<template>
  <div>
    <div class="container">
      <div class="example">
        <a-spin :spinning="loading">
          <a-row type="flex">
            <a-col :md="4" :xs="4" class="right">
              <!--组织树-->
              <a-tree
                default-expand-all
                :tree-data="treeData"
                :selectedKeys.sync="currentNode"
                :replaceFields="{children:'children', title:'deptNameView', key:'key' }"
                :key="treeDataKey"
                @select="onSelect"
              >
                <template #icon="{ key, selected, deptType}">
                  <template>
                    <a-icon type="plus"/>
                  </template>
                </template>
              </a-tree>
            </a-col>
            <a-col :md="19" :xs="19">
              <!--组织表格-->
              <user-table v-if="currentNode.length>0" :current-id="currentNode[0]+''" ref="table" />
            </a-col>
          </a-row>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import UserTable from './components/acUserIndex'
import { deptTree } from '@/api/user-rights/usermanage'

export default {
  name: 'Index',
  components: {
    UserTable
  },
  data () {
    return {
      // 树
      treeData: [],
      // 选中的节点
      currentNode: [],
      // 缓存选中
      cookieCurrent: [],
      loading: false,
      treeDataKey: 1
    }
  },
  mounted () {
    this.handleDeptTree()
  },
  methods: {
    /**
     * taidi
     * 获取组织树信息
     */
    handleDeptTree () {
      this.loading = true
      deptTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.treeData = [{ deptNameView: '全部', key: '1-1', children: undefined }]
          this.treeData[0].children = res.data
          // 默认选择第一个
          this.currentNode[0] = this.treeData[0].key
          // 缓存
          this.cookieCurrent[0] = this.treeData[0].key

          const delPostLoop = (list) => {
            for (let i = 0; list.length < 0; i++) {

                if (list[i].deptType === 2) {
                  delete list[i]
                  return
                }
                if (list[i].children && list[i].children.length > 0) {
                  delPostLoop(list[i].children)
                }
            }
          }

          // const delPostLoop = (arr) => {
          //   // filter和map 有一个本质的区别 就是改变了数组的长度  map 只能修改item 不能删除
          //   return arr.filter((item)=>{
          //     if(item.deptType){
          //       return true
          //     }
          //   }).map((item)=>{
          //     if(item.children){
          //
          //       return item = delPostLoop(item.children)
          //     }
          //     return item
          //   })
          // }
          //
          // delPostLoop(this.treeData)

          this.treeDataKey++
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     *选择组织
     */
    onSelect () {
      // 第二次选择为空，将缓存给它
      if (this.currentNode.length < 1) {
        this.currentNode = this.cookieCurrent
      } else {
        this.cookieCurrent[0] = JSON.parse(JSON.stringify(this.currentNode[0]))
      }
      // 下一个生命周期
      this.$nextTick(() => {
        this.refreshTable()
      })
    },
    /**
     * 刷新表格 currentNode部门key===部门id
     * @param
     */
    refreshTable () {
      this.$refs['table'].deptRefresh()
    }
  }
}
</script>

<style lang="less" scoped>

.container {
  background: #fff;
  padding: 5px;
}

.right {
  border-right: 1px solid #e8e8e8;
}
</style>
